<script setup>
import {onMounted, ref} from 'vue'
import {useHubStore} from '../../stores/Hub/hub';
import { useRoute } from 'vue-router';
import moment from "moment";
import { storeToRefs } from 'pinia';

const route = useRoute();
const hubStore = useHubStore()
const {asset}= storeToRefs(hubStore)

onMounted(async () => {
    await hubStore.getAssetBySerialNumber(route.params.serial_number)
})

const submitting = ref([]);

const getGrade = (grade) => {
    if (grade === 'pass') {
        return 'pass'
    } else {
        return 'fail'
    }
}

const onSubmit = async function (inspectionId, asset) {
    if (submitting.value[inspectionId]) {
        return
    }
    submitting.value = { ...submitting.value, [inspectionId]: true }
    const portalDownloadUrl = `/public/inspections/report/${inspectionId}/${asset.id}`
    axios.get(portalDownloadUrl, {
        responseType: 'blob',
    }).then(response => {
        submitting.value = { ...submitting.value, [inspectionId]: false };

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0');
        let day = today.getDate().toString().padStart(2, '0');
        let fileName = `Inspection_report_${year}-${month}-${day}.pdf`;

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
    }).catch(err => {
        submitting.value = { ...submitting.value, [inspectionId]: false }
        console.error(err)
    })
}

const formatRecommendations = (recommendations) => {
    let safeText = recommendations
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");

      safeText = safeText.replace(/\n/g, "<br>");

      return safeText;
    }
</script>

<template>
    <v-container class="d-flex justify-start pa-0">
        <v-responsive class="align-center">
            <v-row class="header-border px-5 mb-6">
                <v-col cols="6" class="d-flex align-end">
                    <h1 class="text-white font-size-28">Inspections</h1>
                </v-col>
                <v-col cols="6">
                    <a href="/welcome">
                        <v-img position="relative" src="/logo-white-text.png" alt="React app logo" class="pb-1 react-hub-logo"/>
                    </a>
                </v-col>
            </v-row>
            <v-row class="py-1 mb-6 bg-white border-radius-18">
                <v-col cols="12" class="px-3 text-fontDark">
                    <p class="font-weight-bold font-size-20">
                        {{ asset?.asset_type?.name }}
                    </p>
                </v-col>
                <v-col cols="12" class="px-3 py-0 text-fontDark">
                    <p>
                        <strong>Asset Tag Number:</strong> <span>{{ asset?.serial_number }}</span>
                    </p>
                </v-col>
                <v-col cols="12" class="px-3 text-fontDark">
                    <p>
                        <v-icon size="16px" class="d-inline-flex justify-start">fa-light fa-location-dot</v-icon>
                        <span>{{ asset?.site?.name }}</span>
                        <span>{{ asset?.location }}</span>
                    </p>
                </v-col>
            </v-row>
            <v-row class="py-1 bg-white border-radius-18">
                <v-col cols="12" class="px-3 text-fontDark">
                    <p class="font-weight-bold border-spacer font-size-20 pb-2">
                        Available Reports
                    </p>
                </v-col>
                <v-col cols="12" class="px-5 pt-0 font-size-16 text-fontDark pb-4">
                    <template v-if="hubStore.assetGradesWithoutInspection?.length">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <p class="font-weight-bold font-size-16">
                                    Asset Grades:<span class="text-primary">Updates Only</span>
                                </p>
                            </v-col>
                            <template
                                v-for="assetGradeWithoutInspection in hubStore.assetGradesWithoutInspection"
                                :key="assetGradeWithoutInspection.id">
                                <v-col cols="12" class="py-0">
                                    <p class="font-weight-bold">{{
                                            assetGradeWithoutInspection.graded_at ? moment(assetGradeWithoutInspection.graded_at).format('DD/MM/YY') : moment(assetGradeWithoutInspection.created_at).format('DD/MM/YY')
                                        }}</p>
                                </v-col>
                                <v-col cols="12" class="border-spacer pb-3 px-3">
                                    <v-row>
                                        <v-col cols="1">
                                            <div :class="getGrade(assetGradeWithoutInspection.grade)" class="pt-2"><span style="font-size: 8px; font-weight: bold; color:white;">{{assetGradeWithoutInspection.grade}}</span></div>
                                        </v-col>
                                        <v-col cols="9" class="font-size-12 px-4">
                                            <p><strong class="font-weight-bold">Recommendations:</strong> {{ assetGradeWithoutInspection.recommendations?.toString().trimStart().length >= 1 ? formatRecommendations(assetGradeWithoutInspection.recommendations) : 'No recommendation' }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </template>
                        </v-row>
                    </template>
                    <template v-if="hubStore.assetGradesWithInspection">
                        <template v-for="assetGradeWithInspection in hubStore.assetGradesWithInspection" :key="assetGradeWithInspection.id">
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <p class="font-weight-bold font-size-16">
                                        Inspection:<span class="text-primary">{{ assetGradeWithInspection.inspection.title }}</span>
                                    </p>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <p class="font-weight-bold">{{
                                            assetGradeWithInspection.graded_at ? moment(assetGradeWithInspection.graded_at).format('DD/MM/YY') : moment(assetGradeWithInspection.created_at).format('DD/MM/YY')
                                        }}</p>
                                </v-col>
                                <v-col cols="12" class=" pb-3 px-3">
                                    <v-row>
                                        <v-col cols="1">
                                        <div :class="getGrade(assetGradeWithInspection.grade)" class="pt-2"><span style="font-size: 8px; font-weight: bold; color:white;">{{assetGradeWithInspection.grade}}</span></div>
                                        </v-col>
                                        <v-col cols="9" class="font-size-12 px-4">
                                            <p class="font-weight-bold flex-grow-1 ">Recommendations:<span class="font-weight-regular">{{ assetGradeWithInspection.recommendations?.toString().trimStart().length >= 1 ? formatRecommendations(assetGradeWithInspection.recommendations) : 'No recommendation' }}</span></p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="border-spacer pb-3 px-3">
                                    <div class="d-flex gx-3 justify-end">
                                        <v-btn @click="onSubmit(assetGradeWithInspection.inspection.id, asset )" :loading="submitting[assetGradeWithInspection.inspection.id]" color="primary" download prepend-icon="fa fa-download" type="submit" value="Download"> Download</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                    </template>
                    <template v-if="!hubStore.assetGradesWithInspection?.length && !hubStore.assetGradesWithoutInspection?.length">
                        <v-col cols="12" class="px-0 mt-3">
                            <p>There are no reports available.</p>
                        </v-col>
                    </template>
                    <template v-if="!hubStore.assetGradesWithInspection?.length">
                        <v-col cols="12" class="px-0 mt-3">
                            <p>There are no reports available to download.</p>
                        </v-col>
                    </template>
                </v-col>
            </v-row>
        </v-responsive>
    </v-container>
</template>

<style scoped>
.react-hub-logo {
    height: 86px;
}

@media screen and (min-width: 768px) {
    :deep(.react-hub-logo) {
        height: 180px;
    }
}

:deep(.fa-download)::before {
    font-size: 16px;
}

.pass {
    margin-top: 6px;
    background-color: rgb(var(--v-theme-gradeGreenBackground));
    border: 1px solid rgb(var(--v-theme-gradeGreenBorder));
    border-radius: 8px;
    width: 28px;
    height: 28px;
}

.fail {
    margin-top: 6px;
    background-color: rgb(var(--v-theme-gradeRedBackground));
    border: 1px solid rgb(var(--v-theme-gradeRedBorder));
    border-radius: 8px;
    width: 28px;
    height: 28px;
}

body {
    background-color: rgb(var(--v-theme-background)) !important;
}

.border-spacer {
    border-bottom: 2px solid rgb(var(--v-theme-snackbar));
}

.border-radius-18 {
    border-radius: 18px !important;
    padding: 14px;
}

.header-border {
    border-bottom: 3px solid rgb(var(--v-theme-primary));
}

span {
    margin-left: 4px;
}
</style>
