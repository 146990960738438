import '.././bootstrap';
import '.././assets/main.css'
import router from '../router/hub.js'
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia'
import App from './Hub.vue'
import { indexDbPlugin } from '.././plugins/PiniaIndexedDBPersistence';
import { syncPlugin } from '.././plugins/Sync';
import  vuetify from '.././plugins/vuetify';
import DoughnutChart from '.././components/DoughnutChart.vue';
import * as Sentry from "@sentry/vue";
import '.././assets/fontawesome/css/all.css'
import '.././assets/fontawesome/css/fontawesome.css'

const app = createApp(App)
const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(router);
    store.currentRoute = store.router.currentRoute?.value;
});

pinia.use(indexDbPlugin)
pinia.use(syncPlugin)
app.use(vuetify)
app.use(pinia)

Sentry.init({
    app: [app],
    dsn: "https://44342ff5748c46c4ae001744f1fca069@sentry.netready.biz/27",
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    enabled: import.meta.env.VITE_ENV !== 'local',
  });

app.use(router)
app.mount('#app')
