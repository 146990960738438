<script setup>

</script>

<template>
    <div>
        <router-view  :key="$route.fullPath+'layout'" v-slot="{ Component }">
            <transition name="slide-left">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>
