<script setup>
import {ref} from 'vue'
import {useHubStore} from '../../stores/Hub/hub.js';
import HubFindAsset from "../Hub/HubFindAsset.vue";

const itemSerialNumber = ref('')
const errors = ref({user: null})
const invalidQR = ref(false)
const hubStore = useHubStore();

const submit = async () => {
    await hubStore.getAssetBySerialNumber(itemSerialNumber.value)

    if (hubStore.$state.asset) {
        await hubStore.getReports()
        invalidQR.value = false
    } else {
        invalidQR.value = true
    }
}

const updateItemSerialNumber = (serial) => {
    itemSerialNumber.value = serial
}

</script>

<template>
    <v-container class="fill-height d-flex justify-start">
        <v-responsive class="align-center text-center fill-height">
            <v-row>
                <v-col cols="12" class="mb-2">
                    <a href="/welcome">
                        <v-img position="relative" src="/logo-white-text.png" alt="React app logo" class="pb-1 react-hub-logo"/>
                    </a>
                </v-col>
            </v-row>
            <form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12">
                        <h1 class="text-white font-size-28">Welcome to the React Fire Safety Audit System portal...</h1>
                    </v-col>
                    <v-col cols="12">
                        <p class="text-white font-size-20 font-weight-bold">You can access your inspection report from here by scanning one of our React QR code stickers (e.g. the code on the hinge edge of your fire door)</p>
                    </v-col>
                    <v-col cols="12" v-if="invalidQR">
                        <h3 class="text-primaryRed">Invalid QR Code {{ itemSerialNumber.data }}</h3>
                    </v-col>
                    <v-col cols="12">
                        <HubFindAsset @update:item-serial-number="updateItemSerialNumber"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="itemSerialNumber"
                            label="Asset Tag Number"
                            hint="Enter number manually here if you cannot scan the code"
                            persistent-hint
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-btn type="submit" class="w-100" append-icon="fa-solid fa-arrow-right" text="Continue"/>
                    </v-col>
                </v-row>
            </form>
        </v-responsive>
    </v-container>
</template>

<style scoped>
.react-hub-logo {
    height: 118px;
}

@media screen and (min-width: 768px) {
    :deep(.react-hub-logo) {
        height: 380px;
    }
}
</style>
