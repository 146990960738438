import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../stores/user';
import { useAppStore } from '../stores/app.js';
import HubWelcomeView from '../../../resources/js/views/Hub/HubWelcomeView.vue';
import HubInspectionView from '../../../resources/js/views/Hub/HubInspectionView.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            children: [
              {
                path: '',
                name: 'Home',
                component: HubWelcomeView,
              },
            ],
            beforeEnter: async (to, from, next) => {
                const userStore = useUserStore()
                if (userStore.isLoggedIn()) {
                    return next("/welcome");
                }
                next();
            },
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: HubWelcomeView
        },
        {
            path: '/hub-inspection-reports/:serial_number',
            name: 'hub-inspection-reports',
            component: HubInspectionView
        },
    ]
})

router.beforeEach(async (to) => {
    // ✅ This will work because the router starts its navigation after
    // the router is installed and pinia will be installed too
    const userStore = useUserStore();
    await userStore.initUser()
})

export default router
