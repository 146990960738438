<script>
import {qrCode} from '@/helpers/QrScannerWrapper';
import { defineEmits, ref} from 'vue'
import {useHubStore} from '../../stores/Hub/hub';
export default {
    props: ['modelValue'],
    emits: ['update:modelValue', 'update:item-serial-number'],
    components: {},
    mounted: function () {},
    computed: {},
    methods: {
        async setResults(result) {
            const hubStore = useHubStore();
            this.cameraResults = result
            let itemSerialNumber = this.cameraResults.data
            this.hasItemSerialNumber = hubStore.getAssetBySerialNumber(itemSerialNumber)

            if (this.hasItemSerialNumber) {
                this.showQrCodeScanner = false
                this.qrCodeScanner.stop()
                this.qrCodeScanner = null
                this.$emit('update:item-serial-number', itemSerialNumber)
            } else {
                this.invalidQR = true
            }
        },
        startOrStopQrCodeScanner(value) {
            this.$nextTick(() => {
                if (!this.qrCodeScanner && value) {
                    this.qrCodeScanner = qrCode.init(this.$refs.qrFeed, this.setResults)
                    this.qrCodeScanner.start()
                }
                if (this.qrCodeScanner && !value) {
                    this.qrCodeScanner.stop()
                    this.qrCodeScanner = null
                    this.showQrCodeScanner = false
                }
            })
        }
    },
    setup() {
        return {
            cameraResults: ref(),
            qrCodeScanner: null,
            showQrCodeScanner: ref(false),
            showFindAsset: ref(false),
            invalidQR: ref(false),
        }
    },
}
</script>

<template>
    <v-bottom-sheet v-model="showQrCodeScanner" height="50vh" @update:modelValue="startOrStopQrCodeScanner">
        <template v-slot:activator="{ props }">
            <v-btn block v-bind="props" prepend-icon="far fa-qrcode" text="Scan a QR Code"/>
        </template>
        <v-card color="#EAEAEA" height="100%">
            <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="cameraResults && cameraResults.data && invalidQR">
                        <h3 class="text-primaryRed text-center">Invalid QR Code: {{ this.cameraResults.data }}</h3>
                    </v-col>
                    <v-col cols="12">
                        <video class="w-100" ref="qrFeed"/>
                    </v-col>
                    <v-col cols="5" class="d-flex align-center">
                        Not working?
                    </v-col>
                    <v-col cols="7">
                        <v-btn @click="startOrStopQrCodeScanner(false)" prepend-icon="far fa-i-cursor" text="Enter manually"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>
